var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('page-content',{attrs:{"breadcrumbTitle":true,"breadcrumb":[
        {
          title: 'Components',
          link: '/components/components-page',
        },
        {
          title: 'Feedback',
        },
      ]},scopedSlots:_vm._u([{key:"desc",fn:function(){return [_c('p',{staticClass:"h5 mb-0 mt-24 hp-text-color-black-0"},[_vm._v(" Modals are streamlined, but flexible dialog prompts powered by JavaScript and CSS. They support a number of use cases from user notification to completely custom content and feature a handful of helpful sub-components, sizes, variants, accessibility, and more. ")])]},proxy:true}])})],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('basic')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('tooltips-popovers')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('sizing')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('scrolling')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('vertically')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('variants')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('backdrop')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('multiple')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('prevent-closing')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('confirm')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }